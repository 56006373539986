import * as React from "react";
import { observer } from "mobx-react";
import { SingleCardMarqueeProps } from "components/flexComponents/SingleCardMarquee/typings";
import {
  MarqueeCardType,
  SingleCardMarquee as SingleCardMarqueeAd,
} from "@shared-ui/retail-sponsored-content-marquee-cards";

export const SingleCardMarquee = observer((props: SingleCardMarqueeProps) => {
  const { templateComponent, context } = props;

  const { metadata, config } = templateComponent;

  const { id } = metadata;
  const { sci, pageName, variant } = config;
  const cardType: MarqueeCardType = MarqueeCardType.FULL_BLEED;
  const inputs = {
    context,
    sponsoredContentContext: {
      pageName,
      sponsoredContentId: sci,
      variant,
    },
  };

  return (
    <div id={id} data-testid="single-card-marquee">
      <SingleCardMarqueeAd skipSsr inputs={inputs} cardType={cardType} enableAcceptableAds />
    </div>
  );
});

export default SingleCardMarquee;
